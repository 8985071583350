import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import './index.css';
import App from './Repair';
/* import App from './App'; */
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));





root.render(
 /*  <React.StrictMode>
    <App />
 </React.StrictMode> */

 <Router>
   <Routes>
   <Route path="/" Component={App}/>
   </Routes>
 </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

